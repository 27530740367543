import React, { createContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';

import { getAllPublicProviderTenantDetails } from '@scriptscouts/react-platform-client/src/utility/services/Provider';

import { maybeSplitFrom, SEARCH_PROXIMITY_RADIUS_DEFAULT_SELECTED } from '../utils/utils';

import {
  getStoredMedicationContext,
  maybePassivelyPersistToStorage
} from '@scriptscouts/react-platform-client/src/utility/storage';
import { getMedicationIndex, getProductDetails, getProductPrices } from '../utils/product';

const MedicationContext = createContext();

const medicationComparerByIndex = record => m => getMedicationIndex(m) === getMedicationIndex(record);

const MedicationProvider = ({ children }) => {
  const [zipCode, setZipCode] = useState('');
  const [searchRadiusInMiles, setSearchRadiusInMiles] = useState(SEARCH_PROXIMITY_RADIUS_DEFAULT_SELECTED.id);
  const [providers, setProviders] = useState([]);
  const [pharmacies, setPharmacies] = useState([]);

  const [medicationPrices, setMedicationPrices] = useState([]);
  const [medicationsByUuid, setMedicationsByUuid] = useState({});
  const [currentMedications, setCurrentMedications] = useState([]);

  const updatedMedicationPrices = groupedAndMerged => {
    setMedicationPrices(R.compose(R.flatten, R.values)(groupedAndMerged));
  };

  const updatePharmacies = R.compose(
    setPharmacies,
    R.defaultTo([]),
    R.uniq,
    R.flatten,
    R.map(
      R.compose(
        R.flatten,
        R.map(R.pick(['pharmacyName', 'pharmacyImageUrl', 'providerUuid', 'pharmacyAddress' ])),
        R.defaultTo([]),
        R.prop('offers'),
      )
    ),
    R.flatten,
    R.defaultTo([]),
    R.values,
  );

  useEffect(() => {
    updatePharmacies(currentMedications);
  }, [currentMedications]);

  const getMedicationPrices = ({ loggedIn, zipCode, searchRadiusInMiles }) => uuids => {
    const uniqueUuids = R.uniq(uuids);
    const pickUuids = R.compose(
      R.reject(RA.isNilOrEmpty),
      R.flatten,
      R.props(uniqueUuids)
    );

    const pickUuidsAndSetPharmacies = ({ activeMedications, data : groupedData }) => {
      updatePharmacies(activeMedications);

      const uuidsData = pickUuids(groupedData);
      return uuidsData;
    };

    const setMedicationPricesByUuids = newMedicationsByIndex => new Promise(resolve => {
      R.compose(
        promises => Promise.all(promises),
        R.values,
        R.map(medications => {
          const activeMedication = R.find(R.propEq(R.compose(R.prop('drugId'), R.head)(medications), 'drugId'), currentMedications);
          if (!activeMedication) {
            return getMedicationDetails({ loggedIn, searchRadiusInMiles, zipCode })(R.head(medications));
          }
          return Promise.resolve(activeMedication);
        })
      )(newMedicationsByIndex)
        .catch(R.always([]))
        .then(R.reject(RA.isNilOrEmpty))
        .then(newCurrentMedications => {
          setMedicationsByUuid(newMedicationsByIndex);
          setCurrentMedications(newCurrentMedications);
          updatedMedicationPrices(newMedicationsByIndex);

          resolve({ activeMedications : newCurrentMedications, data : newMedicationsByIndex });
        });
    });

    const uuidsToFetch = uniqueUuids;
    if (!uuidsToFetch.length) {
      return Promise.resolve(pickUuidsAndSetPharmacies({ activeMedications : currentMedications, data : medicationsByUuid }));
    }

    return new Promise((resolve, reject) => {
      getProductPrices({ loggedIn, zipCode, searchRadiusInMiles })(uuidsToFetch)
        .then(R.groupBy(R.prop('uuid')))
        .then(setMedicationPricesByUuids)
        .then(pickUuidsAndSetPharmacies)
        .then(resolve)
        .catch(reject);
    });
  };

  const getMedicationDetailsAndSetCurrent = config => payload => {
    return getMedicationDetails(config)(payload)
      .then(setCurrentMedication);
  };

  const getMedicationDetails = ({
    loggedIn,
    searchRadiusInMiles,
    zipCode
  }) => ({
    dosage,
    drugId,
    form,
    quantity,
    uuid,
    name
  }) => {
    return getProductDetails({ loggedIn, searchRadiusInMiles, zipCode })({
      dosage,
      drugId,
      form,
      quantity,
      uuid,
      name
    })
      .then(R.head)
      .then(R.mergeRight({ name, uuid, dosage, drugId, form, quantity, offers : [] }))
      .then(record => {
        const found = R.find(medicationComparerByIndex(record), medicationsByUuid[uuid] ?? []);
        return R.mergeDeepRight(found, record);
      })
      .catch(R.always(null));
  };

  const setCurrentMedication = medication => {
    const mergeWhenEqualsByIndex = R.map(R.ifElse(
      medicationComparerByIndex(medication),
      R.mergeDeepLeft(medication),
      R.omit(['offers'])
    ));

    const getMedicationsMergedByIndex = R.over(
      R.lensProp(medication.uuid),
      mergeWhenEqualsByIndex
    );

    const mergedMedicationsByUuid = getMedicationsMergedByIndex(medicationsByUuid);
    const mergedCurrentMedications = R.map(R.when(R.propEq(medication.drugId, 'drugId'), R.mergeDeepLeft(medication)), currentMedications);

    setMedicationsByUuid(mergedMedicationsByUuid);
    setCurrentMedications(mergedCurrentMedications);
    updatedMedicationPrices(mergedMedicationsByUuid);
  };

  useEffect(() => {
    const storedMedicationData = getStoredMedicationContext();
    storedMedicationData?.zipCode ? setZipCode(storedMedicationData.zipCode) : null;
    storedMedicationData?.searchRadiusInMiles ? setSearchRadiusInMiles(storedMedicationData.searchRadiusInMiles) : null;

    storedMedicationData?.pharmacies ? setPharmacies(storedMedicationData?.pharmacies) : null;

    const urlParams = new URLSearchParams(window.location.search);
    const currentSelectedMedicationProductUuids = maybeSplitFrom(urlParams.get('uuids'));
    if (currentSelectedMedicationProductUuids.length) {
      const getFilteredMedicationPrices = R.filter(R.propSatisfies(R.includes(R.__, currentSelectedMedicationProductUuids), 'uuid'));
      const getFilteredMedicationPricesByUuid = R.pick(currentSelectedMedicationProductUuids);

      storedMedicationData?.medicationPrices ? setMedicationPrices(getFilteredMedicationPrices(storedMedicationData.medicationPrices)) : null;
      storedMedicationData?.medicationsByUuid ? setMedicationsByUuid(getFilteredMedicationPricesByUuid(storedMedicationData?.medicationsByUuid)) : null;
    }

    const hasCachedProviders = storedMedicationData?.providers && !R.isEmpty(storedMedicationData.providers);
    if (hasCachedProviders) {
      setProviders(storedMedicationData.providers);
    } else {
      getAllPublicProviderTenantDetails()
        .then(setProviders)
        .catch(() => setProviders([]));
    }
  }, []);

  useEffect(() => {
    maybePassivelyPersistToStorage({
      medicationContext : {
        zipCode,
        medicationPrices,
        providers,
        pharmacies,
        searchRadiusInMiles,
        medicationsByUuid,
        currentMedications
      }
    });
  }, [zipCode, searchRadiusInMiles, medicationPrices, providers, pharmacies, medicationsByUuid, currentMedications]);

  return (
    <MedicationContext.Provider value={{
      zipCode,
      setZipCode,
      searchRadiusInMiles,
      setSearchRadiusInMiles,
      medicationPrices,
      setMedicationPrices,
      providers,
      pharmacies,
      getMedicationDetails,
      getMedicationDetailsAndSetCurrent,
      getMedicationPrices,
      setCurrentMedications,
      setCurrentMedication,
      currentMedications,
      medicationsByUuid
    }}>
      {children}
    </MedicationContext.Provider>
  );
};

export { MedicationProvider, MedicationContext };

MedicationProvider.propTypes = {
  children : PropTypes.node
};
