import React, { useState, useContext } from 'react';

import { I18nContext } from '@scriptscouts/react-platform-client/src/context/I18nContext';

import {
  StyledWrapperContainer,
  StyledContainer,
  StyledHeader,
  StyledContentContainer,
  StyledReviewCardHeadingContainer,
  StyledReviewCardContainer,
  StyledReviewCardImage,
  StyledReviewCardHeading,
  StyledReviewQuoteImage,
  StyledReviewNameContainer
} from './style';

const ReviewsComponent = () => {
  const { i18n } = useContext(I18nContext);
  const [currentReviewIndex] = useState(0);

  const REVIEWS = [
    {
      id      : 'review-rachel-s',
      name    : 'Rachel S.',
      altText : '',
      review  : i18n('rachelSReview'),
      image   : window.CX.STATIC_IMAGE_ASSETS_URL({
        path      : 'logos/aphora-review',
        extension : 'png'
      }),
      quoteImage : window.CX.STATIC_IMAGE_ASSETS_URL({
        id        : 'aphora-quotation',
        name      : 'Quotation Mark',
        path      : 'cx/aphora-quotation',
        extension : 'png'
      })
    }
  ];

  const currentReviewDetails = REVIEWS[currentReviewIndex];

  return (
    <section id="reviews">
      <StyledWrapperContainer>
        <StyledContainer>
          <StyledContentContainer>
            <StyledHeader as="h2">{i18n('reviewContainerHeader')}</StyledHeader>
            <StyledReviewCardContainer key={currentReviewDetails?.id}>
              <StyledReviewCardImage src={currentReviewDetails?.image} alt={currentReviewDetails?.altText} loading='lazy' />
              <StyledReviewCardHeadingContainer>
                <StyledReviewCardHeading>
                  <StyledReviewQuoteImage src={currentReviewDetails?.quoteImage} loading='lazy' />
                  {currentReviewDetails?.review}
                  <StyledReviewNameContainer><p>{currentReviewDetails?.name}</p></StyledReviewNameContainer>
                </StyledReviewCardHeading>
              </StyledReviewCardHeadingContainer>
            </StyledReviewCardContainer>
          </StyledContentContainer>
        </StyledContainer>
      </StyledWrapperContainer>
    </section>
  );
};

export default ReviewsComponent;
