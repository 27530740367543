import styled from 'styled-components';

import { PrimerOcticons }               from '@scriptscouts/react-platform-client/src/components/common';
import { breakpoints, radius, spacing } from '@scriptscouts/react-platform-client/src/utility/measurements';
import { colors }                       from '@scriptscouts/react-platform-client/src/utility/colors';

const { XIcon } = PrimerOcticons;

export const StyledToken = styled.div`
  box-sizing: border-box;
  background: ${colors['btnBg']};
  border-radius: ${radius['lg']};
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: ${spacing['lg']};
  height: 72px;

  box-shadow: 0px 78px 22px 0px rgba(43, 115, 174, 0.00),
    0px 50px 20px 0px rgba(43, 115, 174, 0.01),
    0px 28px 17px 0px rgba(43, 115, 174, 0.02),
    0px 12px 12px 0px rgba(43, 115, 174, 0.03),
    0px 3px 7px 0px rgba(43, 115, 174, 0.04);

  #medication-token {
    display: flex;
    flex: 1;
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
    gap: ${spacing['xl']};
  }

  #medication-info {
    display: flex;
    flex-direction: row;
    justify-content: center;

    p {
      margin: 0;

      &#medication-name {
        color: ${colors['aphoraNavy']};
        font-size: 18px;
      }

      &#medication-presentation {
        color: ${colors['fgMuted']};
        font-size: 16px;
      }
    }
  }

  #medication-remove {
    display: flex;
    justify-content: flex-end;
    flex: 1;
  }

  @media only screen and (min-width: ${breakpoints['md']}) {
    justify-content: center;
  }
`;

export const StyledCloseIcon = styled(XIcon)`
  cursor: pointer; 
  color: #B0B9C8;
  height: 16px;
  width: 16px;
`;
