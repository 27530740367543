import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';
import * as R from 'ramda';
import { I18nContext } from '@scriptscouts/react-platform-client/src/context/I18nContext';
import { search } from '@scriptscouts/react-platform-client/src/utility/services/Medication';
import { Primer } from '@scriptscouts/react-platform-client/src/components/common';

import SelectedMedicationCard from '../SelectedMedicationCard';

import { MedicationContext } from '../../Context/MedicationContext';

import {
  StyledContainer,
  StyledHeader,
  StyledContentContainer,
  StyledFormContainer,
  StyledSearchImageIcon,
  StyledImageIcon,
  StyledInputContainer,
  StyledDistanceInputsContainer,
  StyledZipcodeInputContainer,
  StyledPrescriptionSearchInput,
  StyledZipcodeInput,
  StyledSearchButton,
  StyledSelectedMedicationsContainer
} from './style';

import { 
  capitalizeFirstLetter 
} from '../../utils/utils';

const { FormControl, Autocomplete } = Primer;

const PRODUCT_INVENTORY_INDEX = 'product-inventory-en';
const SEARCH_TYPE             = 'prefix';

const maybeRenderSelectedMedications = ({ selectedMedications, onRemove }) => {
  if (!selectedMedications?.length) {
    return null;
  }
  
  return (
    <StyledSelectedMedicationsContainer>
      {
        selectedMedications.map(selected => (
          <SelectedMedicationCard
            key={`search-selected-${selected.id}`}
            medication={selected}
            onRemove={onRemove(selected)}
          />
        ))
      }
    </StyledSelectedMedicationsContainer>
  );
};

const SearchDiscountsComponent = () => {
  const { i18n } = useContext(I18nContext);
  const [searchInput, setSearchInput] = useState('');
  const [loadingMedications, setLoadingMedications] = useState(false);
  const [medicationSuggestions, setMedicationSuggestions] = useState([]);
  const [zipCodeValidationError, setZipCodeValidationError] = useState('');
  const [medicationInputValidationError, setMedicationInputValidationError] = useState('');
  const [selectedMedications, setSelectedMedications] = useState([]);

  const {
    setMedicationPrices,
    zipCode,
    setZipCode
  } = useContext(MedicationContext);

  useEffect(() => {
    setMedicationPrices([]);
  }, []);

  const navigate = useNavigate();

  const isZipCodeValid = zipCode => {
    const zipCodeRegex = /^\d{5}(?:[-\s]\d{4})?$/;
    return zipCodeRegex.test(zipCode);
  };

  const searchPrescriptionPrices = () => {
    const isValidZipCode = isZipCodeValid(zipCode);
    const isValidMedication = !R.isEmpty(selectedMedications);

    if (!isValidZipCode || !isValidMedication) {
      !isValidZipCode && setZipCodeValidationError(i18n('enterValidZipCode'));
      !isValidMedication && setMedicationInputValidationError(i18n('enterAtLeastOneMedication'));
      return;
    }

    if (R.isEmpty(selectedMedications)) return;
    const joinByProp = p => R.compose(R.join(','), R.pluck(p));
    const medicationNames = joinByProp('text')(selectedMedications);
    const medicationUuids = joinByProp('id')(selectedMedications);
    navigate(`/medication-prices?medications=${medicationNames}&uuids=${medicationUuids}&zipCode=${zipCode}`);
  };

  const onMedicationSelectedChange = newlySelectedItems => {
    if (!Array.isArray(newlySelectedItems)) {
      return;
    }
    setSelectedMedications(prev => R.uniq([...prev, newlySelectedItems[0]]));
    setSearchInput('');
    setMedicationInputValidationError('');
    setMedicationSuggestions([]);
  };

  const normalizeMedicationName = R.compose(
    R.defaultTo(''),
    R.join(' '),
    R.map(capitalizeFirstLetter),
    R.split(' '),
    R.toLower
  );

  const filterOutSelectedMedications = (selectedMeds = selectedMedications)  => medications => {
    const selectedMedicationNames = R.pluck('text', selectedMeds);
    return R.compose(
      R.reject(R.propSatisfies(R.includes(R.__, selectedMedicationNames), 'text'))
    )(medications);
  };

  const normalizeMedications = R.compose(
    R.defaultTo([]),
    R.map(data => ({ text : normalizeMedicationName(data?.proprietaryName), id : data?.uuid })),
    R.uniqBy(R.compose(R.toLower, R.prop('proprietaryName')))
  );

  const getMedicationsByQuery = query => {
    if (!loadingMedications) {
      setLoadingMedications(true);
      return search({ index : PRODUCT_INVENTORY_INDEX, searchType : SEARCH_TYPE })(query)
        .then(R.pathOr([], ['data']))
        .then(normalizeMedications)
        .then(filterOutSelectedMedications(selectedMedications))
        .then(setMedicationSuggestions)
        .finally(() => setLoadingMedications(false));
    }
  };
  const getMedicationsDebounced = useDebouncedCallback(getMedicationsByQuery, 300);

  const onAutocompleteInputChange = e => {
    const query = e?.target?.value;
    setSearchInput(query);
    if(query && query !== '') {
      getMedicationsDebounced(query);
    } else {
      setMedicationSuggestions([]);
    }
  };

  const removeUuidFromSearch = ({ id }) => () => {
    const updatedSearchMedications = R.reject(R.propEq(id, 'id'))(selectedMedications);
    setSelectedMedications(updatedSearchMedications);
  };

  const onLocationInputChange = e => {
    const zipcodeInput = e?.target?.value;
    setZipCode(zipcodeInput);
    setZipCodeValidationError('');
  };

  return (
    <section id="search-discounts">
      <StyledContainer>
        <StyledContentContainer>
          <StyledHeader as='h1'>{i18n('searchDiscountsHeader')}</StyledHeader>
          <p>{i18n('searchDiscountsDescription')}</p>
          {maybeRenderSelectedMedications({ selectedMedications, onRemove : removeUuidFromSearch })}
          <StyledFormContainer>
            <StyledInputContainer id="search-prescriptions-input-container">
              <FormControl>
                <FormControl.Label id="search-prescriptions-label">{i18n('searchPrescriptionDrugs')}</FormControl.Label>
                <Autocomplete>
                  <StyledPrescriptionSearchInput
                    leadingVisual={<StyledSearchImageIcon />}
                    placeholder={i18n('searchDiscountsPlaceholder')}
                    value={searchInput}
                    onChange={onAutocompleteInputChange}
                  />
                  <Autocomplete.Overlay id="search-prescriptions-autocomplete-overlay" anchorSide="outside-left">
                    <Autocomplete.Menu
                      items={medicationSuggestions}
                      selectedItemIds={[]}
                      aria-labelledby="autocompleteLabel-sortAfterClose"
                      selectionVariant="single"
                      onSelectedChange={onMedicationSelectedChange}
                      emptyStateText={i18n('noSearchResults')}
                      loading={loadingMedications}
                    />
                  </Autocomplete.Overlay>
                </Autocomplete>
                { medicationInputValidationError && <FormControl.Validation variant='error'>{medicationInputValidationError}</FormControl.Validation> }
              </FormControl>
            </StyledInputContainer>
            <StyledDistanceInputsContainer>
              <StyledZipcodeInputContainer>
                <StyledZipcodeInput
                  leadingVisual={<StyledImageIcon />}
                  placeholder={i18n('zipCodePlaceholder')}
                  value={zipCode}
                  onChange={onLocationInputChange}
                  validationStatus={zipCodeValidationError ? 'error' : 'success'}
                />
                { zipCodeValidationError && <FormControl.Validation variant='error'>{zipCodeValidationError}</FormControl.Validation> }
              </StyledZipcodeInputContainer>
            </StyledDistanceInputsContainer>
            <StyledSearchButton type='primary' onClick={searchPrescriptionPrices}>{i18n('medicationSearch')}</StyledSearchButton>
          </StyledFormContainer>
        </StyledContentContainer>
      </StyledContainer>
    </section>
  );
};

export default SearchDiscountsComponent;
