import styled     from 'styled-components';
import { Primer } from '@scriptscouts/react-platform-client/src/components/common';
import { colors } from '@scriptscouts/react-platform-client/src/utility/colors';
import {
  spacing,
  breakpoints
} from '@scriptscouts/react-platform-client/src/utility/measurements';

const { Heading } = Primer;

export const StyledContainer = styled.div`
  color: ${colors['fgMuted']};
  display: flex;
  padding: 0 ${spacing['lg']};
  top: 100px;
  flex-direction: column;
  justify-content: center;
  gap: ${spacing['4xl']};
  align-self: stretch;
  font-family: Gabarito;
  position: relative;
`;

export const StyledHeaderContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing['md']};
  p {
    font-size: 20px;
    font-weight: 500;
    line-height: 150%;
    letter-spacing: 0%;
    color: ${colors['fgMuted']};
    margin: 0;
  }
  @media only screen and (min-width: ${breakpoints['md']}) {
    max-width: 685px;
  }
`;

export const StyledHeader = styled(Heading)`
  display: flex;
  justify-content: space-between;
  margin: 0;
  font-family: Gabarito;
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
  letter-spacing: 0%;
  color: ${colors['aphoraNavy']};
  @media only screen and (min-width: ${breakpoints['md']}) {
    font-size: 48px;
  }
`;

export const StyledProcessCardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${spacing['xl']};
  @media only screen and (min-width: ${breakpoints['md']}) {
    flex-direction: row;
  }
`;

export const StyledProcessCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  radius: 12px;
  padding: ${spacing['lg']} 0px;
  gap: ${spacing['xl']};
  @media only screen and (min-width: ${breakpoints['md']}) {
    padding: ${spacing['xl']};
    gap: ${spacing['2xl']};
  }
  @media only screen and (min-width: ${breakpoints['lg']}) {
    padding: ${spacing['xl']};
    gap: ${spacing['2xl']};
  }
`;

export const StyledProcessCardImage = styled.img`
  width: 167px;
  96.94px;
  margin: 0 auto;
  @media only screen and (min-width: ${breakpoints['md']}) {
    width: 258px;
    height: 151px
  }
`;

export const StyledProcessCardContentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 ${spacing['lg']};
  gap: ${spacing['md']};
`;

export const StyledProcessCardHeading = styled.div`
  font-family: Gabarito;
  font-size: 20px;
  font-weight: 500;
  line-height: 125%;
  letter-spacing: 0%;
  color: ${colors['aphoraNavy']};
`;

export const StyledProcessCardDescription = styled.div`
  font-family: Gabarito;
  font-size: 18px;
  font-weight: 500;
  line-height: 125%;
  letter-spacing: 0%;
  color: ${colors['fgMuted']};
`;
