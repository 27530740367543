import styled from 'styled-components';

import { Primer }               from '@scriptscouts/react-platform-client/src/components/common';
import { colors } from '@scriptscouts/react-platform-client/src/utility/colors';

const { Box } = Primer;

export const StyledBox = styled(Box)`
  background: ${colors['canvasDefault']};
`;
