import React from 'react';
import SearchDiscountsComponent from '../../Components/SearchDiscounts';
import ProcessComponent         from '../../Components/Process';
import ReviewContainer          from '../../Components/Reviews';

import { StyledBox }            from './style';

const Landing = () => {
  return (
    <StyledBox>
      <SearchDiscountsComponent />
      <ProcessComponent />
      <ReviewContainer />
    </StyledBox>
  );
};

export default Landing;
