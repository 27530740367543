import styled from 'styled-components';
import { Primer } from '@scriptscouts/react-platform-client/src/components/common';

import { colors } from '@scriptscouts/react-platform-client/src/utility/colors';
import {
  spacing,
  radius,
  breakpoints
} from '@scriptscouts/react-platform-client/src/utility/measurements';

const { Heading } = Primer;

export const StyledWrapperContainer  = styled.div`
  display: flex;
  padding: ${spacing['lg']};
  gap: ${spacing['4xl']};
  margin-top: ${spacing['4xl']};
  margin-bottom: ${spacing['4xl']};
  overflow-y: auto;
  overflow-x: hidden;
  @media only screen and (min-width: ${breakpoints['sm']}) {
    padding: 0 ${spacing['xl']};
  }
  @media only screen and (min-width: ${breakpoints['md']}) {
    gap: ${spacing['xl']};
  }
`;

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  color: ${colors['textPrimary']};
  padding: 0 ${spacing['lg']};
  gap: ${spacing['2xl']};
  border-radius: ${radius['lg']};
  @media only screen and (min-width: ${breakpoints['md']}) {
    gap: ${spacing['4xl']};
  }
`;

export const StyledHeader = styled(Heading)`
  display: flex;
  margin: 0;
  text-align: left;
  color: ${colors['fgDefault']};
  font-family: Gabarito;
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
  letter-spacing: 0%;
  @media only screen and (min-width: ${breakpoints['md']}) {
  font-size: 48px;
  align-self: flex-start;
  }
`;

export const StyledContentContainer = styled.div`
  display: flex;
  margin-top: 100px;
  background: ${colors['canvasDefault']};
  padding: 0 ${spacing['lg']};
  flex-direction: column;
  align-items: center;
  gap: ${spacing['2xl']};
  box-sizing: border-box;

  @media only screen and (min-width: ${breakpoints['md']}) {
    gap: ${spacing['4xl']};
  }
`;

export const StyledReviewCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing['xl']};

  @media only screen and (min-width: ${breakpoints['md']}) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const StyledReviewCardImage = styled.img`
  display: flex;
  width: 167px;
  height: 217.45px;
  border-radius: 12px;

  @media only screen and (min-width: ${breakpoints['md']}) {
    width: 384px;
    height: 500px;
  }
`;

export const StyledReviewCardHeadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  border-radius: 12px;
  padding: ${spacing['lg']};
  gap: 0;
  background: ${colors['fgOnEmphasis']};
  position: relative;
  box-sizing: border-box;
  align-self: flex-start;
`;

export const StyledReviewCardHeading = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  font-family: Gabarito;
  font-size: 20px;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: 0%;
  padding: 0 0 0 ${spacing['2xl']};
  gap: ${spacing['xl']};
  color: ${colors['btnText']};
  position: relative;
  box-sizing: border-box;
  @media only screen and (min-width: ${breakpoints['sm']}) {
    display: flex;
    justify-content: space-between;
  }
  @media only screen and (min-width: ${breakpoints['md']}) {
    display: flex;
    justify-content: space-between;
  }
`;

export const StyledReviewQuoteImage = styled.img`
  position: absolute;
  left: 0;
`;

export const StyledReviewNameContainer = styled.div`
  font-family: Gabarito;
  font-size: 18px;
  font-weight: 500;
  line-height: 125%;
  letter-spacing: 0%;
  gap: ${spacing['sm']};
`;
