import *  as R from 'ramda';

import { ensureArrayResponse } from './utils';
import {
  getDeferredMedicationOffers,
  getDeferredMedicationProductsAndOffersByUuidsProximityAndProvider,
  getProductsAndOffersByUuidsAndProximity,
  getPublicDeferredMedicationOffers,
  getPublicDeferredMedicationProductsAndOffersByUuidsProximityAndProvider,
  getPublicProductsAndOffersByUuidsAndProximity
} from '@scriptscouts/react-platform-client/src/utility/services/Medication';

const CAPITAL_RX_PROVIDER_UUID = '4ee95293-7b8e-4eb1-ba63-8cc4e558ef48';

const PRODUCT_FETCH_MAP_BY_LOGGED_IN = {
  [true] : {
    medOffer : getDeferredMedicationOffers,
    deferred : getDeferredMedicationProductsAndOffersByUuidsProximityAndProvider,
    products : getProductsAndOffersByUuidsAndProximity
  },
  [false] : {
    medOffer : getPublicDeferredMedicationOffers,
    deferred : getPublicDeferredMedicationProductsAndOffersByUuidsProximityAndProvider,
    products : getPublicProductsAndOffersByUuidsAndProximity
  }
};

const getIndexFromProps = props => R.compose(
  R.join('-'),
  R.props(props)
);
const getMedicationOfferIndex = getIndexFromProps(['providerUuid', 'pharmacyName']);
export const getMedicationIndex = getIndexFromProps(['uuid', 'name', 'form', 'dosage', 'quantity']);

export const getProductDetails = ({
  loggedIn, zipCode, providerUuid = CAPITAL_RX_PROVIDER_UUID
}) => ({
  dosage,
  drugId,
  form,
  quantity,
  uuid
}) => {
  const { medOffer : medicationOfferPromise } = PRODUCT_FETCH_MAP_BY_LOGGED_IN[loggedIn];

  return medicationOfferPromise({
    dosage,
    drugId,
    form,
    quantity,
    providerUuid,
    uuid,
    zipCode
  });
};

export const getProductPrices = ({
  loggedIn, zipCode, searchRadiusInMiles, providerUuid = CAPITAL_RX_PROVIDER_UUID
}) => uuids => {
  const {
    deferred : deferredProductPricesPromise,
    products : productPricesPromise
  } = PRODUCT_FETCH_MAP_BY_LOGGED_IN[loggedIn];

  const hasCapitalRxUuids = R.compose(
    R.complement(R.isEmpty),
    R.filter(R.equals(providerUuid)),
    R.flatten,
    R.pluck('offers'),
    R.map(R.over(R.lensProp('offers'), R.compose(R.pluck('providerUuid'), R.defaultTo([])))),
  );

  const mergeOriginalDataWithDeferredData = regularData => deferredData => {
    const mergeTo = R.curry((mrgWthKyFn, grpByFn, l, r) => R.pipe(
      R.useWith(R.mergeWithKey(mrgWthKyFn),  [R.groupBy(grpByFn), R.groupBy(grpByFn)])(l),
      R.values,
      R.flatten,
    )(r));

    const mergeMedications = (_medicationKey, leftMedications, rightMedications) => {
      const leftMed = leftMedications[0];
      const rightMed = rightMedications[0];

      return [
        {
          ...leftMed,
          ...rightMed,
          offers : R.has('offers', leftMed) ? mergeTo(mergeMedications, getMedicationOfferIndex, leftMed.offers, rightMed.offers ?? []) : undefined
        }
      ];
    };
    return mergeTo(mergeMedications, getMedicationIndex, regularData, deferredData);
  };

  const fetchDeferredHeadPrices = deferredData => new Promise(resolve => {
    const getMedicationsWithQuantity = () => {
      const updatedData = R.compose(
        R.flatten,
        R.map(({ quantities, ...med }) => R.map(quantity => ({ ...med, quantity }), quantities))
      )(deferredData);
      return updatedData;
    };

    const deferredGroupedHeadData = R.compose(R.pluck(0), R.groupBy(R.prop('uuid')))(deferredData);
    return Promise.resolve(deferredGroupedHeadData)
      .then(getMedicationsWithQuantity)
      .then(resolve);
  });

  const fetchDeferredProductPrices = regularData =>
    deferredProductPricesPromise({ uuids, zipCode, providerUuid })
      .then(ensureArrayResponse)
      .then(fetchDeferredHeadPrices)
      .then(mergeOriginalDataWithDeferredData(regularData));

  const maybeFetchDeferredProductPrices = R.unless(
    hasCapitalRxUuids,
    fetchDeferredProductPrices
  );

  return productPricesPromise({ uuids, zipCode, miles : searchRadiusInMiles })
    .then(ensureArrayResponse)
    .then(maybeFetchDeferredProductPrices);
};
