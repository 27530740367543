import styled from 'styled-components';
import { Input, AutoCompleteInput, Button, Primer, PrimerOcticons } from '@scriptscouts/react-platform-client/src/components/common';

import { colors } from '@scriptscouts/react-platform-client/src/utility/colors';
import { radius } from '@scriptscouts/react-platform-client/src/utility/measurements';
import {
  spacing,
  breakpoints
} from '@scriptscouts/react-platform-client/src/utility/measurements';

const { SearchIcon, LocationIcon } = PrimerOcticons;
const { Heading } = Primer;

export const StyledContainer = styled.div`
  background: ${colors['surfaceLighter']};
  color: ${colors['aphoraNavy']};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: ${spacing['3xl']} ${spacing['lg']};
  gap: ${spacing['xl']};
  font-family: Gabarito;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  margin-top: ${spacing['lg']};
  margin-left: ${spacing['lg']};
  margin-right: ${spacing['lg']};
  border-radius: ${radius['lg']};

  @media only screen and (min-width: ${breakpoints['sm']}) {
    align-items: center;
    font-size: 18px;
  }
`;

export const StyledContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  
  @media only screen and (min-width: ${breakpoints['md']}) {
    min-width: 930px;
  }
`;

export const StyledHeader = styled(Heading)`
  margin: 0;
  font-family: Gabarito;
  font-size: 40px;
  font-weight: 500;
  line-height: 125%; /* 37.5px */

  @media only screen and (min-width: ${breakpoints['md']}) {
    font-size: 48px;
  }
`;

export const StyledFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  align-items: flex-end;
  gap: ${spacing['lg']};

  #search-prescriptions-input-container {
    div {
      width: 100%;
      justify-content: center;
    }
  }

  @media only screen and (min-width: ${breakpoints['md']}) {
    flex-direction: row;
  }
`;

export const StyledInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  align-items: flex-end;
  gap: ${spacing['lg']};
  @media only screen and (min-width: ${breakpoints['sm']}) {
    flex-direction: row;
  }

  #search-prescriptions-label {
    color: #4D758E;
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;
  }
`;

export const StyledDistanceInputsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: ${spacing['lg']};
  width: 100%;
  @media only screen and (min-width: ${breakpoints['md']}) {
    width: auto;
  }
`;

export const StyledPrescriptionSearchInput = styled(AutoCompleteInput)`
  box-sizing: border-box;
  span {
    padding-top: 4px;
  }
  input {
    font-size: 16px;
    font-weight: 600;
  }
`;

export const StyledSearchImageIcon = styled(SearchIcon)`
  color: ${colors['textLightMuted']};
  height: 14px;
  width: 14px;
  padding-bottom: 5px;
`;

export const StyledZipcodeInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;

  @media only screen and (min-width: ${breakpoints['md']}) {
    width: auto;
  }
`;

export const StyledZipcodeInput = styled(Input)`
  box-sizing: border-box;
  span {
    padding-top: 4px;
  }
  input {
    font-size: 16px;
    font-weight: 600;
  }
  @media only screen and (min-width: ${breakpoints['md']}) {
    max-width: 125px;
  }
`;

export const StyledImageIcon = styled(LocationIcon)`
  color: ${colors['textLightMuted']};
  height: 14px;
  width: 14px;
  padding-bottom: 5px;
`;

export const StyledSearchButton = styled(Button)`
  width: 100%;
  color: ${colors['btnBg']};
  background: ${colors['accentEmphasis']};
  font-family: Gabarito;
  @media only screen and (min-width: ${breakpoints['md']}) {
    max-width: 180px;
  }
`;

export const StyledSelectedMedicationsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  gap: 30px;
  padding-bottom: 24px;
  flex-wrap: wrap;

  @media only screen and (min-width: ${breakpoints['md']}) {
    flex-direction: row;
    justify-content: flex-start;
  }
`;
