import React from 'react';
import PropTypes from 'prop-types';

import { StyledCloseIcon, StyledToken } from './style';

const SelectedMedicationCard = ({ medication, onRemove }) => {
  return (
    <StyledToken>
      <div id="medication-token">
        <div id="medication-info">
          <img
            width="32px"
            src={`${window.CX.STATIC_IMAGE_ASSETS_URL({
              path    : 'cx/medication-icon.svg',
              options : ['width=32']
            })}`}
            alt="Aphora Rx"
          />
          <div>
            <p id="medication-name">{medication.text}</p>
          </div>
        </div>
        <div id="medication-remove">
          <StyledCloseIcon onClick={onRemove} />
        </div>
      </div>
    </StyledToken>
  );
};

SelectedMedicationCard.propTypes = {
  medication : PropTypes.shape({
    id   : PropTypes.string.isRequired,
    text : PropTypes.string.isRequired
  }).isRequired,
  onRemove : PropTypes.func.isRequired
};

export default SelectedMedicationCard;
